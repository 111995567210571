
.preloader-container{
  min-height: 100dvh;
}

.svg-frame {
  position: relative;
  width: 300px;
  height: 300px;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg-frame svg {
  position: absolute;
  transition: .5s;
  z-index: calc(1 - (0.2 * var(--j)));
  transform-origin: center;
  width: 344px;
  height: 344px;
  fill: none;
}

/* .svg-frame:hover svg {
  transform: rotate(-80deg) skew(30deg) translateX(calc(45px * var(--i))) translateY(calc(-35px * var(--i)));
} */

.svg-frame svg #center {
  transition: .5s;
  transform-origin: center;
}

/* .svg-frame:hover svg #center {
  transform: rotate(-30deg) translateX(45px) translateY(-3px);
} */

#out2 {
  animation: rotate16 7s ease-in-out infinite alternate;
  transform-origin: center;
}

#out3 {
  animation: rotate16 3s ease-in-out infinite alternate;
  transform-origin: center;
  stroke: #ffffff;
}

#inner3,
#inner1 {
  animation: rotate16 4s ease-in-out infinite alternate;
  transform-origin: center;
}

#center1 {
  fill: #ffffff;
  animation: rotate16 2s ease-in-out infinite alternate;
  transform-origin: center;
}

@keyframes rotate16 {
  to {
    transform: rotate(360deg);
  }
}
