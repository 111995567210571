@import url('https://fonts.googleapis.com/css2?family=Acme&family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Lexend:wght@100..900&family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&family=Righteous&display=swap');
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* max-width: 100%;  */
}

body{
  background-color: #000;
}

.homeBackground{
    background-color: #000000;
    /* background: linear-gradient(rgb(255,255,255),rgb(255,255,255),rgb(255,255,255)); */
    height: 90vh;
    width: 100%;
    padding: 45px;
    /* background-image: url("https://res.cloudinary.com/deowghzbg/image/upload/v1719902818/hero_yeslwx.webp"); */
    background-image: url("../Images/hero.webp");
    background-size: cover;  
    background-position: center;
    background-repeat: no-repeat;
}

.carousel-control-prev, .carousel-control-next{
  display: none;
}

.carousel-indicators{
  list-style: none;
}

.contents-h1{
    font-size: 70px;
    font-weight: 900;
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.homeQuotes{
    color: white;
    font-size: 24px;
    font-weight: 600;
}
.video-shadow{
  box-shadow: 0px 0px 10px rgb(0, 0, 0);
}
.home-video {
  height: 550px;
}
@media (max-width: 991.98px) {
    .contents-h1 {
      font-size: 2rem;
    }
    
    .homeQuotes {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 767.98px) {
    .contents-h1 {
      font-size: 1.8rem;
    }
    
    .homeQuotes {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 575.98px) {
    .contents-h1 {
      font-size: 1.5rem;
    }
    
    .homeQuotes {
      font-size: 0.8rem;
    }
  }
  
  
@media screen and (max-width: 940px) {
    .contents-h1{
        color: white;
        font-family: "Righteous", sans-serif;
        font-size: 50px;
        font-weight: 900;
        background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@media screen and (max-width: 380px) {
    .contents-h1{
        color: white;
        font-family: "Nunito Sans", sans-serif;;
        /* font-size: 90px; */
        font-size: 30px;
        font-weight: 900;
        background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
    }
    }
    @media (max-width: 1500px) {
      .home-video {
      max-height: 350px;          
      }
  }
  
  @media (max-width: 1199px) {
      .home-video {
        max-height: 300px;          
      }
  }
  
  @media (max-width: 991px) {
      .home-video {
          max-height: 300px; 
          max-width: 300px;
          box-shadow: none;
      }
  }
  
  @media (max-width: 767px) {
      .home-video {
          max-height: 300px; 
          width: 350px;
          box-shadow: none;
      }
  }
 


