
 @import url('https://fonts.googleapis.com/css2?family=Acme&family=Lexend:wght@100..900&family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

/* removing number input box arrows for chrome, edge and etc*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

 /* removing number input box arrows for firefox browser*/
input[type=number]{ 
    -moz-appearance: textfield;
}
#textArea::placeholder {
    color:rgb(133, 133, 133); /* Change this to the desired color */
}

textarea::placeholder {
    color: white; /* Change this to the desired color */
}
#textArea::placeholder {
    color: white; /* Change this to the desired color */
}

.contactBack {
    /* background: url("https://res.cloudinary.com/deowghzbg/image/upload/v1719902806/newContactBack_q8jnen.webp"); */
    background-image: url("../Images/newContactBack.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.formBack {
    /* background: url(../Images/formBack.png); */
    background: linear-gradient(150deg, #ffffff0f, #5e5e5e0a);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgb(0, 0, 0);
}

.formName, .formSubject {
    border-image: linear-gradient(45deg, rgb(236, 197, 132), rgb(252, 252, 252)) 1;
    background: transparent !important;
    color: white !important;
}

.formEmail, .formPhone {
    border-image: linear-gradient(45deg, rgb(236, 197, 132), rgb(252, 252, 252)) 1;
    background: transparent !important;
    color: white !important;
}

.formMessage {
    border-image: linear-gradient(45deg, rgb(236, 197, 132), rgb(252, 252, 252)) 1;
    background: transparent !important;
    color:  white !important;
} 

.sendMessageButton {
    height: 50px;
    width: 320px;
    border: 2px solid;
    border-image: linear-gradient(45deg, rgb(252, 252, 252), rgb(51, 120, 153)) 1;
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Lexend", sans-serif;

}
.sendMessageButton:hover{
    border-image: linear-gradient(45deg, rgb(51,120,153), rgb(252,252,252)) 1 !important;
    background: -webkit-linear-gradient(45deg, rgb(51,120,153) 20%, rgb(236, 197, 132) 80%);
    background: linear-gradient(45deg, rgb(51,120,153) 20%, rgb(236, 197, 132) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Lexend", sans-serif;

}

.informationBack {
    /* background: url(../Images/informationBack.png); */
    background: linear-gradient(150deg, #ffffff0f, #5e5e5e0a);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgb(0, 0, 0);
    padding: 20px;
}

.officeLabel, .phoneLabel, .emailLabel, .openingLabel {
    background: -webkit-linear-gradient(23deg, rgb(236, 197, 132) 3%, rgb(252, 252, 252) 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.officeQuote, .phoneQuote, .emailQuote, .openingQuote {
    color: white;
}

.officeDetail, .phoneDetail, .emailDetail, .openingDetail {
    color: white;
    font-weight: 600;
}

.officeDetail a{
    color: white;
    text-decoration: none;
}

.phoneDetail a{
    color: white;
    text-decoration: none;
}

.contactTitle {
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px !important;
    font-weight: 500;
    font-family: "Lexend", sans-serif;
}

.contactSubTitle {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.number {
    line-height: 10px;
}
.contact-icon{
    height: 40px;
}

.map iframe {
    width: 100%;
    height: 400px;
    border: 0;
}
iframe{
    padding: 20px;
    margin: 0px;
    width: 100%;
    border-radius: 30px;
   
  }
@media (max-width: 768px) {
    .map iframe {
        height: 300px;
    }
}

@media (max-width: 576px) {
    .map iframe {
        height: 200px;
    }
}

@media (max-width: 992px) {
    .formBack,
    .informationBack {
        height: auto;
    }
}

