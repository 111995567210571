.projectBlock{
    background-color: black;
    padding: 20px 0;

}

  .projectCard {
    /* width: 100%; */
    width: 300px; 
    height: 300px !important; 
    background: linear-gradient(rgb(8, 8, 8),rgb(17, 17, 73),rgb(8, 8, 8));
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(54, 54, 54);
    box-shadow: 0px 0px 10px rgb(36, 35, 35);
    cursor: pointer;
  }
  .projectCard {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .projectCard:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
  .projectCount{
    margin: 10px 0;
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px !important;
    font-weight: 400;
    font-family: "Lexend", sans-serif;
  }
  .projectTitle{
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px !important;
    font-weight: 500;
    font-family: "Lexend", sans-serif;
  }
  .prjectSubTitle{
    color: white;
    font-size: 25px;
    font-family: "Lexend", sans-serif;

  }
  