@import url('https://fonts.googleapis.com/css2?family=Acme&family=Lexend:wght@100..900&family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&display=swap');
*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;

}
.serviceBack{
    /* background-image: url("https://res.cloudinary.com/deowghzbg/image/upload/v1719902701/Circle_line_vu1ovm.webp"); */
    background-image: url("../Images/CircleLine.jpg");
    background-position: center ;
    background-repeat: no-repeat;
    background-size: cover; 

}

.serviceIcon{
   color: rgb(202, 172, 121);
}
.service-icon{
    height: 45px;
    width: 45px;
}
.learn-more-icon{
    height: 25px;
}
.serviceTitle{
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
    font-weight: 500;
    font-family:  "Lexend", sans-serif;

}
.serviceSubTitle{
    color: white;
    font-size: 20px;
    font-weight: 400;
    width: 100%;
} 

.serviceCard{
    height:100%;
    background-color: #000000;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px; /* Adjust the margin value as needed */
    display: flex;
    flex-direction: column;
    /* background: url("https://res.cloudinary.com/deowghzbg/image/upload/v1719902806/serviceCard_xbebsr.webp"); */
    background: linear-gradient(220deg, rgb(136 136 136 / 18%) 0%, rgb(136 136 136 / 14%) 40%, rgba(94, 94, 94, 0.1) 100%, rgba(0, 0, 255, 0.1) 100%);
    /* background-image: url("../Images/serviceCard.webp"); */
}
.serviceCardTitle{
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30px !important;
    font-weight: 500;
}
.serviceCardSubTitle{
    color: rgb(252, 252, 252) !important;
    font-size: 20px;
    text-align: justify;
    font-weight: 400;
}


.serviceCard {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .serviceCard:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }