  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
     /* background: url("https://res.cloudinary.com/deowghzbg/image/upload/v1719902696/footerBack_e0ztxo.webp"); */
     /* background-image: url("../Images/footerBack.jpg"); */
     background: linear-gradient(247deg, #171F23, #26353B, #070707);
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    transition: 0.4s;

  }
  
  .colorChange{
    width: 100%;
    position: fixed;
    height: 10vh;
    box-shadow: 5px 5px 10px rgb(17, 40, 51) ;
  }

  .nav-container {
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 10vh;
    
  }
  
  .nav-logo {
    text-decoration: none;
    height: 10vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
   

  }
  .nav-logo img{
height: 6vh;
margin-bottom: 18px;


  }
  
  .nav-logo .icon {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-left: 16px;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
  }
  
  .nav-links {
    font-size: 17px;
    font-family: "Josefin Sans", sans-serif;
    text-decoration: none;
    font-weight: bold;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    transition: width 0.5s ease, background-color 0.5s ease;
    cursor: pointer;
    color: white;
  }
  .nav-links:hover{
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  
  .nav-item {
    line-height: 30px;
    margin-right: 1rem;
    color: #f5b921;
  }

  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.5s ease, background-color 0.5s ease;
    

  }
  
  .nav-item:hover:after {
    margin: 0 auto;
    width: 60%;
    background-image: linear-gradient(rgb(236,197,132),rgb(252,252,252));
  }
 
  .nav-icon {
    display: none;
    color: #f5b921;
  }
  
  .nav-icon .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
  }
 
  @media screen and (max-width: 1050px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 10vh;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
      padding: 20px;
    }
  
    .nav-menu.active {
      background:rgb(11, 10, 16);;
      background-color: rgb(11, 10, 16);
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: -1;
    }
    .nav-item .active {
      color: #ffdd40;
      border: none;
    }
    .nav-item:hover:after {
      margin: 0 auto;
      width: 20%;
      background-image: linear-gradient(rgb(236,197,132),rgb(252,252,252));
    }
    .nav-links {
      width: 100%;
      display: table;
    }
  
    .nav-logo .icon {
      width: 2rem;
      height: 2rem;
    }
  
    .nav-icon {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-100%, -50%);
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      color: #ffdd40;
    }
  }
  