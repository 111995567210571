.discoverBack{
    /* background: url("https://res.cloudinary.com/deowghzbg/image/upload/v1719902697/discoverBack_qajhnb.webp"); */
    background-image: url('../Images/newContactBack.webp');
    background-color: black;
    background-position: center ;
    background-repeat: no-repeat;
    background-size: cover; 
}
.discoverCard{
    border: none;
 
}
.discoverCardBack{
    /* background: url("https://res.cloudinary.com/deowghzbg/image/upload/v1719902693/discoverCardBack_yhwbct.webp"); */
    background: url(../Images//discoverCardBack.png);
    background-color: black;     
    box-shadow: 0px 0px 10px rgb(0, 0, 0);
    border-radius: 5px;
}
.discoverTitle{
    background: -webkit-linear-gradient(20deg, rgb(236, 197, 132) 10%, rgb(252, 252, 252) 40%);
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
    font-size: 35px !important;
    font-weight: 500;
    font-family: "Lexend", sans-serif;
}
.discoverSubTitle{
    color: white;
    font-size: 20px;
    font-weight: 400;
}


.discoverImage {
    /* width: 100%; */
    min-height: 330px;
    height: auto;
}

.discover-icon{
    height: 42px;
}

@media (min-width: 1200px) {
    .discoverImage {
        height: 330px; 
        width: 430px;   
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .discoverImage {
        height: auto; 
    }
}

@media (max-width: 767px) {
    .discoverImage {
        height: auto; 
        width: 100%;
    }
}
