/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Rowdies:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Rowdies:wght@300;400;700&display=swap'); */
.form-apply{
    padding: 100px;
}
.email-form{
    padding: 15px;
    font-size: 20px;
    border-radius: 10px;
}
input[type="email"]::placeholder {
    color: rgb(133, 133, 133); /* Change this to the desired color */
}
input[type="text"]::placeholder {
    color:rgb(133, 133, 133); /* Change this to the desired color */
}
input[type="number"]::placeholder {
    color:rgb(133, 133, 133); /* Change this to the desired color */
}
input[type="textarea"]::placeholder {
    color:rgb(133, 133, 133); /* Change this to the desired color */
}
input[type="contact"]::placeholder {
    color:rgb(133, 133, 133); /* Change this to the desired color */
}
input[type="file"]::placeholder {
    color:rgb(133, 133, 133); /* Change this to the desired color */
}
textarea::placeholder {
    color:rgb(133, 133, 133); /* Change this to the desired color */
}
#textArea::placeholder {
    color:rgb(133, 133, 133); /* Change this to the desired color */
}
.applyBack{
    /* background: url("https://res.cloudinary.com/deowghzbg/image/upload/v1719902806/newContactBack_q8jnen.webp"); */
    background-image: url("../Images/newContactBack.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}

.glass{
    padding: 30px;
    border-radius: 5px;
    /* background: url("https://res.cloudinary.com/deowghzbg/image/upload/v1719644921/applyFormBack_yiuuno.png"); */
    background: linear-gradient(rgba(63, 62, 62, 0.116), rgba(61, 61, 61, 0.219));
    box-shadow: 0px 0px 10px rgb(0, 0, 0);
}

.applyFormTitle{
    text-align: center;
    margin-bottom: 20px; 
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px !important;
    font-weight: 600;
}


@media (min-width: 992px) {
    .applyFormTitle {
      font-size: 40px !important; /* Adjust as needed */
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .applyFormTitle {
      font-size: 35px !important; /* Adjust as needed */
    }
  }
  
  @media (max-width: 767px) {
    .applyFormTitle {
      font-size: 35px !important; /* Adjust as needed */
    }
  }


#formName, #formEmail, #formPhone{
    border: 1px solid ;
    border-image: linear-gradient(45deg, rgb(236,197,132), rgb(252,252,252)) 1 ;
    color: white;
    background: transparent;
}
#formExp, #formChoose{
    border: 1px solid;
    border-image: linear-gradient(45deg, rgb(236,197,132), rgb(252,252,252)) 1 ;
    color: white;
    background: transparent;
}

#textArea{
    border: 1px solid ;
    border-image: linear-gradient(45deg, rgb(236,197,132), rgb(252,252,252)) 1 ;
    color: white !important;
    background: transparent;
}
.applySubmitButton{
    height: 50px;
    width: 320px;
    border: 2px solid !important;
    border-image: linear-gradient(45deg, rgb(252,252,252), rgb(51,120,153)) 1 !important;
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Lexend", sans-serif;

}
.applySubmitButton:hover{
    border-image: linear-gradient(45deg, rgb(51,120,153), rgb(252,252,252)) 1 !important;
    background: -webkit-linear-gradient(45deg, rgb(51,120,153) 20%, rgb(236, 197, 132) 80%);
    background: linear-gradient(45deg, rgb(51,120,153) 20%, rgb(236, 197, 132) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Lexend", sans-serif;

}
.email{
    margin-top: 20px;          
    border-radius: 5px;
    font-size: 15px;
    font-family: "Rowdies", sans-serif;
    font-weight: 100;
     background-color: transparent;
     color: white;
     border: 1px solid;
    border-image: linear-gradient(45deg, rgb(236,197,132), rgb(252,252,252)) 1;     
}

.email option{
    font-family: "Encode Sans", sans-serif;
    padding: 30px;
}

.Submit_form{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.Submit_form button{
    padding: 10px 30px;
    /* border-radius: 40px; */
    letter-spacing: 2px;
    font-weight: bold;
    border: none;

}
.form-card{
    padding: 40px;
    border-radius: 20px;
}
.application{
    color: red;
}
.application::before{
    content: "";
    position: absolute;
    left: 1;
    bottom: -10px;
    background-color: #e91e63;
    height: 2px;
   
    box-sizing: border-box;
    width: 40%;
   
  
}
@media(max-width:470px){
    /* input[type="email"]::placeholder {
        color: white;
    }
    input[type="text"]::placeholder {
        color: white;
    }
    input[type="contact"]::placeholder {
        color: white;
    }
    input[type="file"]::placeholder {
        color: white;
    }
    textarea::placeholder {
        color: white;
    }
   */
    .email{
        margin-top: 20px;
        padding: 10px;
        border-radius: 5px;
        font-size: 12px;
    border-color: white;
    }
    .Submit_form button{
        padding: 5px 10px;
        font-size: 10px;
    }

}

