*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
#dis a{
    text-decoration: none;
    color:white ;
}
.discoverButton{
    height: 50px;
    width: 250px;
    border: 2px solid;
    border-image: linear-gradient(45deg, rgb(252,252,252), rgb(51,120,153)) 1;
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;  
    -webkit-text-fill-color: transparent;
}
.discoverButton:hover{
    border-image: linear-gradient(45deg, rgb(51,120,153), rgb(252,252,252)) 1 !important;
    background: -webkit-linear-gradient(45deg, rgb(51,120,153) 20%, rgb(236, 197, 132) 80%);
    background: linear-gradient(45deg, rgb(51,120,153) 20%, rgb(236, 197, 132) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 25px; 
}
.aboutTitle {
    text-align: center;
    margin-bottom: 20px; 
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px !important;
    font-weight: 400;
}
 .textBackground{
    background-color: #000000!important;
    /* background-image: url("https://res.cloudinary.com/deowghzbg/image/upload/v1719902703/newAboutBack_s45f7s.webp");  */
    background: url(../Images//newAboutBack.png);
    border: none !important;
    background-position: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
} 
.aboutSubTitle{
    color: rgb(252, 252, 252) !important;
    font-size: 22px;
    text-align: center;
    font-weight: 500;
}