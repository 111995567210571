*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.footer{
    box-shadow: 0px 0px  10px rgb(0, 0, 0);
}

.footerBack{
    /* background: url("https://res.cloudinary.com/deowghzbg/image/upload/v1719902696/footerBack_e0ztxo.webp"); */
    background-image: url("../Images/footerBack.jpg");
    background-size: cover;
}
.fullWidthContainer {
    padding-left: 0;
    padding-right: 0px;
}
.footer-col .social-links{
   display: flex;
   flex-direction: column;
   gap: 10px;
}
.footer-col .social-links a{
    display: inline-block;
    height: 45px;
    width: 45px;
    margin: 0 15px 10px 0;
    text-align: center;
    line-height: 45px;
    border-radius: 10px;
    /* color: #ffffff; */
    transition: all 0.2s ease;
    font-size: 18px;
}
.bottom-1,.bottom-2,.bottom-3{
    margin-top: 80px;
}
.social-div{
    display: flex;
    align-items: center;
}
.social-div:hover .innerSocial {
    margin-left: 20px;
    transition: all 0.3s ;
}
.innerSocial{
    display: flex;
    width: 100%;
    transition: all .3s;
    align-items: center;
}

.social-div a{
    text-decoration: none;
    color: #fff;
}


.footerTitle{
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 10%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}
.footerSubTitle{
    color: white !important;
}

.service li:hover{
    transition: all 0.3s;
    margin-left: 15px;
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 10%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.company li:hover{
    transition: all 0.3s;
    margin-left: 15px;
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 10%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subscribeBack{
    border-image: linear-gradient(45deg, rgb(236, 197, 132), rgb(252, 252, 252)) 1;
    background: transparent !important;
    color: white !important;
}
 .subscribeButton{
    height: 50px;
    border: 2px solid !important;
    border-image: linear-gradient(45deg, rgb(252,252,252), rgb(51,120,153)) 1 !important;
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 20%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Lexend", sans-serif;

 }
 .subscribeButton:hover {
    border-image: linear-gradient(45deg, rgb(51,120,153), rgb(252,252,252)) 1 !important;
    background: -webkit-linear-gradient(45deg, rgb(51,120,153) 20%, rgb(236, 197, 132) 80%);
    background: linear-gradient(45deg, rgb(51,120,153) 20%, rgb(236, 197, 132) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Lexend", sans-serif;

}

.Toastify__spinner {
    border-top-color: #3498db !important; /* Change this color to your desired loading color */
    border-right-color: #3498db !important; /* Change this color to your desired loading color */
  }

 .companyName{
    background: -webkit-linear-gradient(45deg, rgb(236, 197, 132) 10%, rgb(252, 252, 252) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 }
 
 .foot{
    color: white;
}
